import './styles/style.scss'
import './styles/main.scss'

window.onload = () => {

  document.querySelector('.playlist a').addEventListener('click', event => {
    document.querySelectorAll('.playlist-player').forEach(element => element.classList.add('expanded'))
    event.preventDefault()
    event.stopPropagation()
    event.stopImmediatePropagation()
  })

  document.querySelector(".playlist-player").addEventListener("mouseleave", event => {
    event.stopPropagation()
    document.querySelector('.playlist-player').classList.remove("expanded")
  })

  document.querySelector('.icon').addEventListener('click', event => {
    event.preventDefault()
    document.querySelector('.disclaimer.newsletter').parentNode.removeChild(disclaimer)
  })

  document.querySelectorAll('.featured-sub-item, .featured-item').forEach(element => element.addEventListener('click', event => {
    event.preventDefault()
    event.stopPropagation()
    window.location = element.querySelector('a').getAttribute('href')
  }))

  document.querySelectorAll('.articles .views-field-field-cover').forEach(element => element.addEventListener('click', event => {
    event.preventDefault()
    window.location = element.nextElementSibling.querySelector('a').getAttribute('href')
  }))

  document.querySelectorAll('.articles .views-field').forEach(element => element.addEventListener('click', event => {
    event.preventDefault()
    window.location = element.querySelector('a').getAttribute('href')
  }))

  document.querySelector('.featured-sub-full .le-label').addEventListener('click', () =>
    window.location = '/label'
  )

  document.querySelector('.featured-sub-full .rock-a-la-casbah').addEventListener('click', () =>
    window.location = '/radio'
  )

  document.querySelector('.featured-sub-full .webzine').addEventListener('click', () =>
    window.location = '/webzine'
  )

  document.querySelectorAll('.latest-chroniques .latest-item').forEach(element => element.addEventListener('click', event => {
    event.preventDefault()
    window.location = element.querySelector('a').getAttribute('href')
  }))

  const banniere = document.querySelector('.banniere')
  if (banniere) {
    const { color, link } = banniere.dataset
    document.querySelector('.page').style.background = color
    document.querySelector('.featured-sub').style.background = color
    banniere.querySelector('img').addEventListener('click', () => window.location = link)
  }
}
